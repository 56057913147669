<template>
 <v-container class=" survey" >
    
    <SurveyForm :code="code"/>   
  </v-container>

</template>
<style>
.mosaic__links{
  text-decoration: none;
}
.has-overlay-gradient {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.5)25%, transparent 75%);
}

</style>
<script>
// @ is an alias to /src
import SurveyForm from '@/components/SurveyForm.vue'
import axios from 'axios'

export default {
  name: 'home',
  props: ['etab'],
  components: {
     SurveyForm
  },
  beforeRouteEnter (to, from, next) {
    axios.interceptors.request.use(
      function (config) {
        config.headers['Accept-Language']=to.params.locale
        return config;
      }
    );
    axios.get('/etab/' + to.params.etab)
          .then((response) => {
            next(vm => vm.setData(null,response.data))
          })
          .catch((err) => {
            next(vm => vm.setData(err, null))
          })
    
  }, 
  // when route changes and this component is already rendered,
  // the logic will be slightly different.
  beforeRouteUpdate (to, from, next) {
    this.content = null
    axios.interceptors.request.use(
      function (config) {
        config.headers['Accept-Language']=to.params.locale
        return config;
      }
    );
    axios.get('/etab/' + to.params.etab)
      .then((response) => {
        this.setData(null,response.data)
        next()
      })
      .catch((err) => {
        this.setData(err, null)
        next()
      })

  },
  data () {
    return {
      loading: false,
      error: null,
      content: null,
      code: this.$route.params.code
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = this.content = null
      this.loading = true
      axios.interceptors.request.use(
        function (config) {
          config.headers['Accept-Language']=this.$route.params.locale
          return config;
        }
      );
      axios.get('/etab/' + this.etab).then(response => {
        this.content =  response.data
        this.loading = false
        document.title = `Alpine Resorts - Room Directory - `
      })
      
    },
    setData (err, content) {
      if (err) {
        this.error = err.toString()
      } else {
        this.content = content
        document.title = `Alpine Resorts - Room Directory - `
      }
    }
  }
}
</script>
