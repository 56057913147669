<template>
    <v-card min-height="100vh" :loading="loading" class="d-flex flex-column" elevation="0">
      <template slot="progress">
        <v-progress-linear
          color="primary"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
        <v-card-title v-if="!submitted" class="text-center justify-center pb-0 pt-5">{{$t('contents.survey.title')}}</v-card-title>
        <v-card-text class="px-2 pt-2" v-if="!submitted" style="padding-bottom:100px">
            <div class="pa-5 " v-html="$t('contents.survey.introduction')"></div>
            <v-divider></v-divider>
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-col
                    cols="12"
                    md="12"
                    class="px-0"
                >
                <SurveyQuestion 
                    v-for="(q, key) in questions"
                    v-bind:key="`question-${key}`"
                    v-bind:activeIndex="step"
                    v-bind:index="key+1"
                    v-bind:question="q"
                    :id="`question-${key}`"
                    @input="handleChange"
                    @subinput="handleSubChange"
                ></SurveyQuestion>
                <div class="text-center py-0" :id="`question-${questions.length}`" 
                    v-if="step == (questions.length + 1) && questions.length != 0"
                > 
                    <v-card class="pa-4 mb-5 text-left">
                      <strong>{{questions.length + 1}}. Afin d’améliorer la qualité de nos services, n’hésitez pas à nous en dire plus sur votre expérience</strong>
                      <v-textarea
                        v-model="message"
                        name="input-7-1"
                        auto-grow
                        value=""
                      ></v-textarea>
                    </v-card>
                    <v-btn
                        :disabled="invalid"
                        color="primary"
                        class="mr-4"
                        
                        @click="validate"
                        >
                        {{$t('btns.send')}}
                        </v-btn>

                        
                </div>
                    
                    
                </v-col>
              </v-form>
            </validation-observer>
        </v-card-text>
        <v-alert v-else
          prominent

          text
          type="success"
          transition="dialog-bottom-transition"
          class="alert-column text-center px-5"
          style="padding-top:70px; padding-bottom:70px;"
        >
          <div class="title pb-3 pa-5" v-html="$t('contents.survey.thanks')">
          </div>
          <div v-html="$t('contents.survey.thanksMsg')" class="pa-5"></div>
        </v-alert>
      </v-card>
      
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import SurveyQuestion from '@/components/SurveyQuestion.vue'
import axios from 'axios'

export default {
    props: ['id', 'show', 'code'],
    components: {
      ValidationObserver,
      SurveyQuestion
    },
    data: () => ({
      step: 1,
      questions: [],
      error: null,
      submitted: false,
      Feedback: true,
      v0: true,
      valid: true,
      fullname: '',
      email: '',
      loading: false,
      appartement: '',
      message: '',
      rating_residence: 5,
      rating_appartement: 5,
      rating_comeback: 5,
      rating_equips_appartement: 5,
      rating_equips_residence: 5,
      select: null,
      checkbox: false
    }),
    beforeCreate() {
        axios.get('/questions/')
          .then((response) => {
              this.questions = response.data
              this.questions.forEach(question => {
                question.rating = 0
                question.sub_questions.forEach(sub => {
                  sub.rating = 0
                });
              });
          })
          .catch((err) => {
              this.error = err
          })
        
    },
    methods: {
      handleChange(idx, val){
        const question = this.questions[idx-1]
        question.rating = val
        this.questions.splice(idx-1, 1, question)
        
        if(idx >= this.step){
           this.step = idx + 1 
           
        }
      },
      handleSubChange(idx, subidx, val){
        const question = this.questions[idx-1]
        question.sub_questions[subidx-1].rating = val
        this.questions.splice(idx-1, 1, question)
        
      },
      handleValidationErrorAdvanced () {
        const firstField = Object.keys(this.errors.collect())[0];
        // this assumes you have a conviention of ref and field name here I just add the
        // Input suffix to the field name as you can see in the template.
        this.$refs[`${firstField}`].scrollIntoView();
      },
      validate () {
        this.loading = true;
        var that = this;
        let code = that.code;
        let questions = that.questions;
        if(this.$refs.observer.validate()){
          var bodyFormData = new FormData();
              bodyFormData.append('code', code)
              bodyFormData.append('questions', JSON.stringify(questions))
              bodyFormData.append('message', this.message)
        //   bodyFormData.append('rating.residence', this.rating_residence)
        //   bodyFormData.append('rating.equips_residence', this.rating_equips_residence)
        //   bodyFormData.append('rating.appartement', this.rating_appartement)
        //   bodyFormData.append('rating.equips_appartement', this.rating_equips_appartement)
        //   bodyFormData.append('rating.comeback' , this.rating_comeback)
        //   bodyFormData.append('message', this.message)
             bodyFormData.append('etab', this.$route.params.etab)
             bodyFormData.append('_token', window.csrfToken)
             axios({
              method: 'post',
              url: '/api/survey',
              data: bodyFormData,
              headers: {'Content-Type': 'multipart/form-data' }
             })
             .then(function () {
                  
                  that.$refs.form.reset()
                  that.submitted = !that.submitted
                  that.loading = false;
                  
             })
             .catch(function (response) {
                  that.error = response
                  that.loading = false;
             });
             
       }else{
           return this.handleValidationErrorAdvanced();
       }
        window.scrollTo(0, 0);
      },
      reset () {
        this.$refs.form.reset()
      },
    }
    
    
  }
</script>
<style>
.v-rating .accent--text {
    color: #b4b4b4 !important;
    caret-color: #b4b4b4 !important;
}
.alert-column > .v-alert__wrapper{
  flex-direction: column;
}
.alert-column > .v-alert__wrapper > .v-icon{
  margin-top: 2rem;
  margin-right:0;
  margin-left:0;
  margin-bottom:2rem;
}
</style>