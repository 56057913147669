<template>
    <v-expand-transition>
        <li v-if="isDisplayed()" class="mt-5">
            {{question.label}}
            <v-rating
                v-model="selfRating"
                length="10"
                class="mb-3 mt-2"
                style="
                    display: flex;
                    justify-content: space-between;
                "
            >
                <template v-slot:item="props">
                <v-icon
                    
                    class="px-0"
                    
                    :color="props.isFilled ? 'green' : 'grey lighten-1'"
                    v-text="`mdi-numeric-${props.index + 1}-box`"
                    @click="props.click"
                ></v-icon>
                </template>
            </v-rating>
        </li>

    </v-expand-transition>
</template>
<script>

export default ({
    props: ['index', 'question','globalrating'],
    data: () => {
        return {
            rating: 0
        }
    },
    computed:{
        selfRating: {
            get() {
                return this.question.rating
            },
            set(val) {
                this.rating = val
                this.$emit('input', this.index, val)
            }
        }
    },
    methods: {
        isDisplayed() {
            if(this.question.conditions == 'equal' && this.globalrating == this.question.note_principale && this.globalrating != 0 ){

                return true;
            }
            if(this.question.conditions == 'greaterthan' && this.globalrating > this.question.note_principale && this.globalrating != 0){

                return true;
            }
            if(this.question.conditions == 'lowerthan' && this.globalrating < this.question.note_principale && this.globalrating != 0){

                return true;
            }
            if(this.question.conditions == 'greaterequalthan' && this.globalrating >= this.question.note_principale && this.globalrating != 0){

                return true;
            }
            if(this.question.conditions == 'lowerequalthan' && this.globalrating <= this.question.note_principale && this.globalrating != 0){

                return true;
            }
            return false;
        }
    }
})
</script>
