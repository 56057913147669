<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: .5
        }"
        transition="slide-y-transition"
    >
    <v-card elevation="0" class="pa-4 my-4" v-show="activeIndex >= index" :key="index">
        <strong class="d-inline-block mb-4">{{index}}. {{question.question_label}}</strong>
        <v-rating
            v-model="selfRating"
            style="    justify-content: space-around;display: flex;"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            hover
            dense
            size="48"
            length="5"
            x-large
            class="py-5"
        >
            <template v-slot:item="props">
                <v-icon
                :color="props.isFilled ? genColor(props.index) : 'grey lighten-1'"
                large
                @click="props.click"
                >
                {{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                </v-icon>
            </template>
        </v-rating>
        <v-row class="d-flex px-5 pt-2" style="justify-content:space-between;">
            <small class="grey--text">{{$t('contents.survey.bad')}}</small>
            <small class="grey--text">{{$t('contents.survey.good')}}</small>
        </v-row>
        <ul ref="subQuestions" v-show="question.sub_questions.length > 0 && rating !=0" class="my-0" style="
                list-style: none;
                padding: 0 1rem;
            ">
            <SubQuestion 
                v-for="(sub, key) in question.sub_questions"
                :key="`sub-${key}`"
                :question.sync="sub"
                :globalrating="rating"
                v-bind:index="key+1"
                @input="subQuestionInput"
            ></SubQuestion>
        </ul>

    </v-card>
    </v-lazy>
</template>
<script>
import SubQuestion from '@/components/SubQuestion.vue'

export default ({
    name: 'SurveyQuestion',
    components: {
        SubQuestion
    },
    props: ['index', 'question','activeIndex'],
    data: () => {
        return {
            conditions: null,
            rating: 0
        }
    },
    computed:{
        selfRating: {
            get() {
                return this.question.rating
            },
            set(val) {
                this.rating = val
                /*let count = 0;
                this.question.sub_questions.forEach(element => {
                    if(element.conditions == 'equal' && val == element.note_principale && val != 0 ){

                        count++
                    }
                    if(element.conditions == 'greaterthan' && val > element.note_principale && val != 0){

                        count++
                    }
                    if(element.conditions == 'lowerthan' && val < element.note_principale && val != 0){

                        count++
                    }
                    if(element.conditions == 'greaterequalthan' && val >= element.note_principale && val != 0){

                        count++
                    }
                    if(element.conditions == 'lowerequalthan' && val <= element.note_principale && val != 0){

                        count++
                    }
                });
                this.counter = count*/
                this.$emit('input', this.index, val)
            }
        }
    },
    methods: {
        subQuestionInput(idx, val) {
            this.$emit('subinput', this.index, idx, val)
        },
        isActive: function() {
            return this.activeIndex >= this.index
        },
        genColor(idx){
                return `green darken-${idx}`
        }
    }
})
</script>
